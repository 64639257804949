import React from 'react'
import { Header } from "../../components/header/Header"
import GlobalStyles from '../../Global'

export const Tabelle = () => {
  return (
    <>
        <GlobalStyles></GlobalStyles>
        <Header />
    </>
  )
}
